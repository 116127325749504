//DASHBOARD STYLES
.dashboard-container {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 20px;

    @include responsive-for(lg) {
      grid-template-columns: 1fr;
    }
    &-three-dots {
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
    }
  }
}

.google-analytics {
  display: grid;
  row-gap: 20px;

  &-copy {
    cursor: pointer;
    align-items: center;
    display: flex;
    color: $defaulttextcolor;

    &-icon {
      margin-right: 6px;
    }

    &:hover {
      color: $grey100;
    }

    textarea {
      // display: none;
      opacity: 0;
      z-index: -100;
      position: absolute;
    }
  }

  .collapse-title {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-family: $avertaSemiBold;
    }
  }

  .collapsebody {
    display: grid;
    row-gap: 20px;
  }

  .topic {
    width: 100%;
    border-radius: 4px;
    border: none;
    font-size: $extrathin;
    font-family: $avertaSemiBold;
    color: $defaulttextcolor;

    &::placeholder {
      font-family: $avertaSemiBold;
      color: $grey;
    }

    &:focus {
      background-color: $white;
      outline-offset: 0px;
      outline: none;
    }
  }

  .select > div {
    display: grid;
    row-gap: 20px;

    label {
      font-family: $avertaBold;
    }

    select {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $defaultborder;
      padding: 10px 12px;
      font-size: $extrathin;
      background-color: $grey400;
      font-family: $avertaSemiBold;
      color: $defaulttextcolor;
    }
  }

  .select.timeline {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
