.modal {
  &-input-container {
    margin-top: 20px;
    p {
      font-family: $avertaBold;
    }
  }
}

.group {
  span {
    font-family: $avertaBold;
    font-size: $text;
    color: black;
  }
}
