// COMP WRAPPER STYLES// COMP WRAPPER STYLES
.compwrapper-container {
  padding-left: 110px;
  padding-right: 2vw;
  padding-bottom: 60px;

  @include responsive-for(lg) {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .compwrapper {
    padding: 15px;
  }
}
