.inputfield {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $defaultborder;
  padding: 10px 12px;
  font-size: $extrathin;
  background-color: $grey400;
  font-family: $avertaSemiBold;
  color: $defaulttextcolor;

  &::placeholder {
    font-family: $avertaSemiBold;
    color: $grey;
  }

  &:hover {
    background-color: $grey300;
  }

  &:focus {
    background-color: $white;
    outline-offset: 0px;
    outline: none;
    border: 1px solid $primarycolor;
  }

  &.error {
    border: 1px solid $red;

    &:focus {
      border: 1px solid $red;
    }
  }
}
