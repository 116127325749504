.button {
  outline: none;
  border: none;
  // min-width: 200px;
  padding: 10px 20px;
  border-radius: 4px;
  font-family: $avertaBold;
  font-size: $text;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: background 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &.primary {
    background: $primarycolor;
    color: $white;
    font-family: $avertaSemiBold;
    &:hover {
      background: darken($primarycolor, 5%);
    }

    &:active {
      background: darken($primarycolor, 10%);
    }
  }

  &.default {
    background: $black;
    color: $white;
    font-family: $avertaSemiBold;
    &:hover {
      background: lighten($black, 5%);
    }

    &:active {
      background: lighten($black, 10%);
    }
  }

  &.ghost {
    background: $white;
    color: $black;
    font-family: $avertaSemiBold;
    &:hover {
      background: darken($white, 5%);
    }

    &:active {
      background: darken($white, 10%);
    }
  }

  &.fit-content {
    width: 100%;
  }

  &:disabled {
    background: $grey;
    cursor: default;
    &:hover {
      background: $grey;
    }

    &:active {
      background: $grey;
    }
  }
}
