.sideoptions-wrapper {
  position: fixed;
  width: 400px;
  height: 100%;
  top: 0;
  background: $white;
  z-index: 10;

  .container {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    position: relative;

    .closeIcon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      .close {
        stroke: $red;
        &:hover {
          stroke: $black;
        }
      }
    }
  }
}
